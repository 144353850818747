import type {AxiosInstance} from 'axios'
import {type GmDtoLibraryDtoOrderDto, ProjectApi } from '~/gen/openapi/sblService'
import { useAxiosClient } from '~/use/axios/client'
import { extractErrorMessagesFromErrorOrResponse } from '~/helpers/errors'

type State = {
  nonInvoicedOrders: GmDtoLibraryDtoOrderDto[]
  isLoadingNonInvoicedOrders: boolean
  nonInvoicedOrdersFetchError?: string
}

const state = reactive<State>({
  nonInvoicedOrders: [],
  isLoadingNonInvoicedOrders: false,
  nonInvoicedOrdersFetchError: undefined
})

const nonInvoicedOrders = computed(() => state.nonInvoicedOrders)
const isLoadingNonInvoicedOrders = computed(() => state.isLoadingNonInvoicedOrders)
const nonInvoicedOrdersFetchError = computed(() => state.nonInvoicedOrdersFetchError)

const axiosWrapper = (axios: AxiosInstance) => {
  const projectApi = new ProjectApi(
    null,
    '',
    axios
  )

  const loadNonInvoicedOrders = async () => {
    state.isLoadingNonInvoicedOrders = true
    try {
      const response = await projectApi.projectOrdersNotInvoiced()
      state.nonInvoicedOrders = response.data
    } catch (error) {
      state.nonInvoicedOrdersFetchError = extractErrorMessagesFromErrorOrResponse(error)[0]
    } finally {
      state.isLoadingNonInvoicedOrders = false
    }
  }

  return {
    loadNonInvoicedOrders,
    nonInvoicedOrders,
    isLoadingNonInvoicedOrders,
    nonInvoicedOrdersFetchError,
  }
}

export const useProjectOrdersStore = () => {
  const { axiosClient } = useAxiosClient()
  return axiosWrapper(axiosClient.value)
}
